@font-face {
  font-family: 'Redaction';
  src: url('assets/fonts/Redaction-Regular.woff2') format('woff2');
       font-weight: 200;
       font-style: normal;
}

@font-face {
  font-family: 'Redaction35';
  src: url('assets/fonts/Redaction_35-Regular.woff2') format('woff2');
       font-weight: 400;
       font-style: normal;
}

@font-face {
  font-family: 'Redaction50';
  src: url('assets/fonts/Redaction_50-Bold.woff2') format('woff2');
       font-weight: 400;
       font-style: normal;
}


// VARIABLES

$break-small: 750px;
$site-margin: 28px;
$site-margin-mobile: 20px;




// GENERAL

html {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}




// COLOR

.color-overlay{
  width: 100%;
  height: 100%;
  background-color: hsl(97, 97%, 27%);
  mix-blend-mode: screen;

  position: fixed;
  z-index: 100;
  pointer-events: none;
}





// TYPE

h1{
  font-family: 'Redaction35';
  font-size:  clamp(36px, 4vw, 56px);
  font-weight: 400;

  margin: 10px 0;
}

h2{
  font-family: 'Redaction';
  font-size: clamp(22px, 3vw, 38px);
  font-weight: 200;

  margin: 5px 0;
}

h3{
  font-family: 'Redaction50';
  font-size: clamp(18px, 3vw, 30px);
  font-weight: 400;
}

p{
  font-family: 'Redaction';
  font-size: clamp(18px, 3vw, 30px);
  font-weight: 200;
  line-height: 1.3;
}



// CONTENT

header{
  padding: $site-margin;

  .hero-image{
    width: 100%;
  }

  .logo{
    margin-top: -10vw;

    @media screen and (max-width: $break-small){
      margin-top: 0vw;
    }
    
    path{
      fill: #231F20;
    }
  }

  .intro{
    width: 100%;
    max-width: 1000px;

    margin: $site-margin auto;
    text-align: center;

    @media screen and (max-width: $break-small){
      margin: calc($site-margin * 1) auto 0;
    }

    
  }
}



section{
  display: flex;
  align-items: center;

  @media screen and (max-width: $break-small){
    flex-wrap: wrap;
  }

  padding: calc($site-margin * 2) $site-margin;

  .content-wrap, .image-wrap{
    width: 50%;

    @media screen and (max-width: $break-small){
      width: 100%;
    }
  }

  

  .copy-wrap{
    max-width: 600px;
    margin-right: 50px;
    margin: 0 auto;
  }

  .image-wrap{ 
    img{
      width: 100%;
    }
  }
}

@media screen and (max-width: $break-small){
  .mobile-flip .content-wrap{
    order: 2;
  }
}



// NEWSLETTER


.newsletter {
  width: 100%;
  padding: calc(3 * $site-margin) $site-margin ;
  margin: 0 auto;

  background-color: #EBEBEB;
  border-radius: 10px;

  @media screen and (max-width: $break-small){

  }

  .signup-title{
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  form {
    position: relative;
    text-align: center;
    display: block;
    margin: 0 auto;

    font-family: "Redaction";
    font-weight: 500;
    font-size: clamp(18px, 3vw, 30px);

    input {
      border: none;
      background-color: transparent;
      width: 100%;
      padding: 0 $site-margin ;

      color: black;
      text-align: center;

      @media screen and (max-width: $break-small){

      }


      input::-webkit-input-placeholder {
        opacity: 1;
        color: black;
      }

      &:focus::placeholder {
        color: rgba($color: black, $alpha: 0.25);
      }

      &:focus {
        outline: none;
        border: none;

      }
    }

    .klaviyo_field_wrap{
      font-size: clamp(22px, 3vw, 38px);
      margin: $site-margin;
    }

    .klaviyo_messages {
      font-family: "Redaction35";

      & > div {
        margin: 20px 0 0 ;
      }
    }

    .klaviyo_form_actions{
      position: relative;
    }

    button {
      border: none;
      background-color: transparent;
      position: relative;
      cursor: pointer;
      color: black;
      font-weight: 400;

      &:hover {
        transition: opacity ease 400ms;
        opacity: 0.75;
      }
    }
  }
}